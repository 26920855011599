import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DashboardSubActionHeader from "shared/components/DashboardSubActionHeader";
import Logo from "../../assets/logo.svg";
import DashboardSubHeader from "shared/components/DashboardSubHeader";
import {
  ActionBtn,
  EmptyContainer,
  StatusContainer,
  TableActions,
  ViewAllBtn,
} from "./Styles";
import EmptyState from "shared/components/FreeComp/EmptyState";
import Table from "shared/components/Table";
import { filteredDataFn } from "shared/utils/filteredData";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { GradientLoader, Icon } from "shared/components";
import api from "shared/utils/api";
import toast from "shared/utils/toast";
import { makeLive } from "store/actions/live";
import { Modal } from "shared/components";
import triggerHandleToggle from "shared/utils/handleToggle";
import { getStatusColor } from "shared/utils/statusColor";
import { setService } from "store/actions/serviceAction";
import AddAccount from "Services/AddAccount";
import consoleLog from "shared/utils/consoleLog";
import shouldPerform, { getRoleName } from "shared/utils/assignRoles";
import summaryData from "shared/utils/dataSummary";
import { currencyFormat100, maskString } from "shared/utils/moneyFormat";
import {
  getStoreLocalStorage,
  storeToLocalStorage,
} from "shared/utils/authToken";

function TransactionList({ setPageView, setObjDetails }: any) {
  const [searchData, setSearchData] = useState("");
  const [filteredColumn, setFilteredColumn] = useState("");
  const service = useSelector((store: RootStateOrAny) => store.service);
  // const live = useSelector((store: RootStateOrAny) => store.live); //work on this....
  const business = useSelector((store: RootStateOrAny) => store.business);
  const currentUser = useSelector((store: RootStateOrAny) => store.currentUser);
  const [transactionData, setTransactionData] = useState([] as any);
  const [recordTitle, setRecordTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredFormState, setFilteredFormState] = useState<any>({});

  const [params, setParams] = useState<any>({});
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [fetchedPayload, setFetchedPayload] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [modalObj, setModalObj] = useState<any>({});
  const [openDownload, setOpenDownload] = useState<boolean>(false);

  const [start, setStart] = useState<string | undefined>("");
  const [end, setEnd] = useState<string | undefined>("");

  // const [live, setLive] = useState(service.live ?? false);
  const history = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const columnHeaders = [
    // { id: "id", label: "Transaction ID" },
    { id: "status", label: "Status" },
    { id: "updatedAt", label: "Date" },
    { id: "reference", label: "Reference" },
    { id: "name", label: "Payer" },
    { id: "email", label: "Customer email" },
    { id: "gatewayChannel", label: "Channel" },
    // { id: "amount", label: "Amount" },
    { id: "amountpaid", label: "Amount" },
    // { id: "remitedAmount", label: "Settled amount" },
    { id: "charge", label: "Charge" },
    // { id: "narration", label: "Transaction Description" },
  ];
  //// console.log("Fetch data: ", fetchedData);
  let data =
    fetchedData instanceof Array
      ? fetchedData.map((el: any) => {
          let obj: any = {};
          obj.name = el?.Customer?.fullName;
          obj.email = el?.Customer?.email;
          obj.medium = el?.paymentMode;
          obj.transactionId = el?.reference;
          obj.updatedAt =
            el?.updatedAt &&
            new Date(el?.updatedAt).toLocaleString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

          obj.amount =
            el.amount && `${currencyFormat100(el.amount, el.paymentCurrency)}`;
          obj.amountpaid =
            el.amountPaid &&
            `${currencyFormat100(el.amountPaid, el.paymentCurrency)}`;


          obj.charge =
            el.charge && `${currencyFormat100(el.charge, el.paymentCurrency)}`;
          obj.remitedAmount =
            el.remitedAmount &&
            `${currencyFormat100(el.remitedAmount, el.paymentCurrency)}`;
          obj.rstatus =
            el.status && el.status.toLowerCase().includes("init")
              ? "In progress"
              : el?.status?.toLowerCase()[0].toUpperCase() +
                el?.status?.toLowerCase().substring(1);
          obj.status =
            el.status &&
            (el.status.toLowerCase().includes("init") ? (
              <div style={{ color: getStatusColor("pending") }}>
                In progress
              </div>
            ) : (
              <div style={{ color: getStatusColor(el?.status) }}>
                {el?.status?.toLowerCase()[0].toUpperCase() +
                  el?.status?.toLowerCase().substring(1)}
                  {el.flag=='SUSPECTED' &&
                    <Icon type={'warining'} color="red" size={15} title="Suspected transaction" />
                  }
              </div>
            ));

          return { ...el, ...obj };
        })
      : [];

  const myFunction = (val: any) => {
    history.push(`/my-apps/${service?.id}/transactions/${val?.id}`, {
      presentData: filteredFormState,
    });
  };

  data = filteredDataFn(data, columnHeaders, searchData, filteredColumn);
  const rowCount = transactionData.length ?? 0; ///live

  const getTransactions = async (check?: string, otherObj?: any) => {
    setLoading(true);
    try {
      let live = service?.mode === "LIVE";
      let useLive = check === "1" ? !live : live;
      //consoleLog("Filtered Object: ", otherObj);

      let reqObj = {
        id: service?.id,
        mode: useLive ? "LIVE" : "TEST",
        ...otherObj,
      };
      let currentPage: any = getStoreLocalStorage("currentPage");
      if (currentPage && !reqObj?.page) {
        reqObj.page = currentPage;
      } else {
        storeToLocalStorage("currentPage", reqObj?.page);
      }
      consoleLog("Request: ", reqObj);

      const response = await api.get(
        `/services/${service?.id}/transactions`,
        reqObj
      );

      // consoleLog(response, "respTrans");

      if (
        response?.status == true &&
        response?.payload?.data &&
        response?.payload?.data instanceof Array
      ) {
        if (response?.payload) {
          setFetchedData(response.payload.data);
          setTransactionData(response.payload.data);
          setRecordTitle(response.message ?? "");
          setFetchedPayload(response.payload);

          //await dispatch(setService(response.payload));
          //setServiceDetails(response.payload);
        } else {
          toast.error("Unable to fetch Service Details");
        }
      } else {
        // toast.error(response.message);
        setTransactionData([]);
        setRecordTitle(response?.message ?? "");
      }

      //consoleLog(response, "responseServ");
    } catch (err) {
      consoleLog(err, "error");
      setTransactionData([]);
      // @ts-ignore
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${day} / ${month} / ${year}`;
  const transactionFilter = [
    {
      id: "createdAt",
      type: "date",
      title: "Date",
      list: [
        {
          title: "Start Date",
          name: "startDate",
          type: "start",
          value: currentDate,
        },
        { title: "End Date", name: "endDate", type: "end", value: currentDate },
      ],
    },
    {
      id: "status",
      type: "flag",
      title: "Status",
      list: [
        { title: "Success", name: "status.successful", checked: false },
        { title: "Failed", name: "status.failed", checked: false },
        { title: "In progress", name: "status.terminated", checked: true },
      ],
    },

    {
      id: "medium",
      type: "flag",
      title: "Channels",
      list: [
        { title: "Card Payment", name: "card", checked: true },
        { title: "QR Pay", name: "qr", checked: true },
        { title: "Direct Debit", name: "direct_debit", checked: true },
        { title: "eBanking", name: "ebanking", checked: true },
        { title: "Transfer", name: "transfer", checked: true },
        { title: "Mobile Money", name: "mobile_money", checked: true },
      ],
    },

    {
      id: "search",
      type: "text",
      title: "Search",
      name: "search",
      description: "Search wih transaction ID",
    },
  ];

  const SearchForData = (e: any) => {
    let searchedArr: any = [];
    transactionData.forEach((transaction: any) => {
      if (transaction.narration.toLowerCase().indexOf(e.toLowerCase()) !== -1) {
        searchedArr.push(transaction);
      }
    });
    setFetchedData(searchedArr);
  };

  const filterTransactions = async (obj: any) => {
    consoleLog("Search object: ", obj);
    obj = obj instanceof Object ? obj : {};
    storeToLocalStorage("currentPage", 1);
    setFilteredFormState(obj);
    await getTransactions("2", {
      ...obj,
      // page: 1,
      // limit: 10,
    });
  };

  const goToPage = async (val: number) => {
    let obj: any = { ...filteredFormState };
    consoleLog({ filteredFormState });
    obj.page = val;
    await getTransactions("2", obj);
  };

  const resetFilter = () => {
    setFetchedData(transactionData);
  };

  useEffect(() => {
    let obj: any = {};
    if (location.state?.presentData instanceof Object) {
      obj = { ...location.state.presentData };
      setFilteredFormState(obj);
    } else {
      const date = new Date();
      let currentDay = String(date.getDate()).padStart(2, "0");
      let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
      let currentYear = date.getFullYear();

      date.setUTCDate(date.getUTCDate() - 30);

      const last7Days = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getUTCDate()
        .toString()
        .padStart(2, "0")}`;
      // we will display the date as DD-MM-YYYY
      //let currentDate = `${currentYear}-${currentMonth}-${last7Days}`;
      let endDate = `${currentYear}-${currentMonth}-${currentDay}`;
      setStart(last7Days);
      setEnd(endDate);
      // card, direct_debit, qr, ebanking, transfer, mobile_money
      obj = {
        start: last7Days,
        end: endDate,
        search: "",
        successful: true,
        failed: true,
        terminated: true,
        card: true,
        qr: true,
        transfer: true,
        direct_debit: true,
        ebanking: true,
        mobile_money: true,
      };
    }

    filterTransactions(obj);
  }, []);

  if (!shouldPerform(getRoleName(currentUser, business?.id), "VTrans")) {
    history.push("/authenticate");
    return null;
  }

  return (
    <>
      <div>
        <DashboardSubHeader
          title={`${rowCount} Transactions`}
          btnTitle='Download Summary'
          callBackFn={() => setOpenDownload(!openDownload)}
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          dateFilter={false}
          setSearchData={SearchForData}
          withSearch={false}
          withFilter //{transactionData.length > 0 ? true : true}
          filterType='transaction'
          arrToFilter={transactionFilter}
          resetFilter={resetFilter}
          applyFilter={filterTransactions}
          getParams={setParams}
          clickedParam={"Transaction Description"}
          widthActionBtn={transactionData.length > 0 ? true : false}
          excelExport
          excelExportData={summaryData(rowCount, columnHeaders, data)}
          setFilterFormState
        />
        {loading ? (
          <GradientLoader />
        ) : (
          <>
            {rowCount === 0 ? (
              <EmptyContainer>
                <EmptyState stateTitle={"No Transaction"} />
              </EmptyContainer>
            ) : (
              <>
                <Table
                  headData={columnHeaders}
                  bodyData={data ?? []}
                  hoverLink
                  serialNumber
                  clickFunction={myFunction}
                  totalPages={fetchedPayload?.totalPages ?? 0}
                  currentpage={fetchedPayload?.currentPage ?? 0}
                  numberOfItems={fetchedPayload?.numberOfItems ?? 10}
                  goToPage={goToPage}
                />
              </>
            )}
          </>
        )}
      </div>
      <Modal
        isOpen={visible}
        width={800}
        withCloseIcon={true}
        onClose={() => setVisible(false)}
        overFlow={true}
        containerWidth={false}
        renderContent={() => (
          <AddAccount setVisible={setVisible} setReload={() => {}} />
        )}
      />
    </>
  );
}

export default TransactionList;
